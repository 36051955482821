import { TBreadcrumbItem } from '@pbx/shared/ui/common/breadcrumb';

import { TBreadcrumbPageItem } from '../breadcrumb-container.types';

export function getSlugArray(pathname: string) {
  return pathname.split('/').filter((slug) => slug !== '');
}

export function getBreadcrumbItemsBySlug(
  slugArray: string[],
  pages: TBreadcrumbPageItem[]
) {
  return slugArray
    .reduce((breadcrumbItems, currentSlug, index) => {
      const slugToCheck = slugArray
        .slice(0, slugArray.length - index)
        .join('/');

      const pageBySlug = pages.find((page) => page.slug === slugToCheck);
      if (!pageBySlug) return breadcrumbItems;

      return [
        ...breadcrumbItems,
        {
          title: pageBySlug.title,
          slug: `/${pageBySlug.slug}`,
        },
      ];
    }, [] as TBreadcrumbItem[])
    .reverse();
}

export function getBreadcrumbItems({
  slugArray,
  pages,
}: {
  slugArray: string[];
  pages: TBreadcrumbPageItem[];
}): TBreadcrumbItem[] {
  // Remove the current page from breadcrumb
  slugArray.splice(-1);

  const itemsBySlug: TBreadcrumbItem[] = getBreadcrumbItemsBySlug(
    slugArray,
    pages
  );

  return [{ title: 'Home', slug: '/' }, ...itemsBySlug];
}
