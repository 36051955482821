import React from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

export const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<'li'>
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn('inline-flex items-center gap-[4px]', className)}
    {...props}
  />
));

BreadcrumbItem.displayName = 'BreadcrumbItem';
