import React from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

export const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<'ol'>
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn('flex flex-wrap items-center gap-2 break-words', className)}
    {...props}
  />
));

BreadcrumbList.displayName = 'BreadcrumbList';
