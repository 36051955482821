import React from 'react';

import { Icon } from '@pbx/shared/ui/common/icon';

export const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<'div'>) => (
  <div role="presentation" aria-hidden="true" className={className} {...props}>
    {children ?? (
      <Icon
        name="disclosure-arrow-up"
        width={12}
        height={12}
        className="rotate-90"
      />
    )}
  </div>
);

BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';
