import { Link } from '@pbx/shared/ui/common/link';
import { cn } from '@pbx/shared/utilities/combine-class-names';
import { textVariants } from '@pbx/shared/ui/common/text';

import { TBreadcrumbProps } from './breadcrumb.types';
import {
  hiddenOnMobileVariants,
  iconVariants,
  linkVariants,
} from './breadcrumb.variants';
import { BreadcrumbContainer } from './breadcrumb-container/breadcrumb-container';
import { BreadcrumbList } from './breadcrumb-list/breadcrumb-list';
import { BreadcrumbItem } from './breadcrumb-item/breadcrumb-item';
import { BreadcrumbLink } from './breadcrumb-link/breadcrumb-link';
import { BreadcrumbSeparator } from './breadcrumb-separator/breadcrumb-separator';

export function Breadcrumb({ items, isPrimary }: TBreadcrumbProps) {
  return (
    <BreadcrumbContainer>
      <BreadcrumbList>
        {items.map(({ title, slug }, index) => {
          const visibleOnMobile = index === 0 || index === items.length - 1;
          const needSeparator = index < items.length - 1;

          return (
            <BreadcrumbItem
              key={`breadcrumb-item${index}`}
              className={hiddenOnMobileVariants({
                hiddenOnMobile: !visibleOnMobile,
              })}
            >
              <BreadcrumbLink asChild>
                <Link
                  href={slug}
                  className={cn(
                    textVariants({
                      variant: 'paragraph',
                      size: 'medium-tight',
                    }),
                    linkVariants({ isPrimary })
                  )}
                >
                  {title}
                </Link>
              </BreadcrumbLink>
              {needSeparator && (
                <BreadcrumbSeparator
                  className={cn(
                    hiddenOnMobileVariants({
                      hiddenOnMobile: !visibleOnMobile,
                    }),
                    iconVariants({ isPrimary })
                  )}
                />
              )}
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbList>
    </BreadcrumbContainer>
  );
}
