import React from 'react';
import { Slot } from '@radix-ui/react-slot';

export const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a';

  return <Comp ref={ref} className={className} {...props} />;
});

BreadcrumbLink.displayName = 'BreadcrumbLink';
