'use client';
import React from 'react';
import { usePathname } from 'next/navigation';

import { Breadcrumb } from '@pbx/shared/ui/common/breadcrumb';

import { getBreadcrumbItems, getSlugArray } from './breadcrumb-wrapper.helpers';
import { TBreadcrumbWrapperProps } from './breadcrumb-wrapper.types';

export const BreadcrumbWrapper = ({
  pages,
  isPrimary,
}: TBreadcrumbWrapperProps) => {
  const pathname = usePathname();

  // Hide breadcrumb on home
  if (pathname === '/') return null;

  const slugArray = getSlugArray(pathname);
  const breadcrumbItems = getBreadcrumbItems({ slugArray, pages });

  return <Breadcrumb items={breadcrumbItems} isPrimary={isPrimary} />;
};
