import { z } from 'zod';

import { emptyStringLiteralValidation } from '../string/string';

export const amountValidation = (fieldName: string, minValue = 1) => {
  return z.union([
    z.coerce
      .number()
      .int({ message: `${fieldName} must be a whole number` })
      .gt(minValue, {
        message: `${fieldName} must be greater than ${minValue}`,
      }),
    emptyStringLiteralValidation,
  ]);
};
