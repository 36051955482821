import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Text, textVariants } from '@pbx/shared/ui/common/text';

import { TLabel, TLabelElement } from './label.types';

export const Label = React.forwardRef<TLabelElement, TLabel>(
  (
    {
      className,
      helper,
      helperSize = 'small-tight',
      labelSize = 'default-500',
      ...props
    },
    ref
  ) => {
    const isLarge = labelSize.includes('large-');
    return (
      <div className={cn('flex flex-col', className)}>
        <LabelPrimitive.Root
          ref={ref}
          className={cn(
            textVariants({ variant: 'label', size: labelSize }),
            isLarge ? 'text-foreground-highlight' : ''
          )}
          {...props}
        />
        {helper && (
          <Text
            variant="paragraph"
            size={helperSize}
            className="text-foreground-secondary"
          >
            {helper}
          </Text>
        )}
      </div>
    );
  }
);
Label.displayName = LabelPrimitive.Root.displayName;
