import { Text, THTMLProps } from '@pbx/shared/ui/common/text';
import { Icon } from '@pbx/shared/ui/common/icon';
import { cn } from '@pbx/shared/utilities/combine-class-names';

export function Error({ className, ...props }: THTMLProps): JSX.Element {
  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Icon name="exclamation" width={20} className="text-foreground-error" />
      <Text
        variant="paragraph"
        size="small-tight"
        className="text-foreground-error"
        {...props}
      />
    </div>
  );
}

Error.displayName = 'Error';
