import { cva } from 'class-variance-authority';

import { EPlacement } from './icon.types';

export const variant = cva('', {
  variants: {
    variant: {
      string: 'text-foreground-primary',
      icon: 'text-foreground-highlight 1',
      wrapper: 'transform absolute top-1/2 -translate-y-1/2',
    },
    disabled: {
      true: 'text-foreground-disabled',
      false: '',
    },
    placement: {
      [EPlacement.PREFIX]: 'pl-4',
      [EPlacement.SUFFIX]: 'pr-4 -translate-x-full',
    },
  },
});
