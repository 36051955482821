export enum EPlacement {
  PREFIX = 'prefix',
  SUFFIX = 'suffix',
}

export type TIconProps = {
  children: React.ReactNode | string;
  disabled?: boolean;
  placement?: EPlacement;
};
