import { cva } from 'class-variance-authority';

export const hiddenOnMobileVariants = cva('', {
  variants: {
    hiddenOnMobile: {
      true: 'sm:hidden',
      false: '',
    },
  },
});

export const iconVariants = cva('', {
  variants: {
    isPrimary: {
      true: 'text-brand-foreground-highlight',
      false: 'text-foreground-highlight',
    },
  },
});

export const linkVariants = cva('hover:underline', {
  variants: {
    isPrimary: {
      true: 'text-brand-foreground-secondary hover:text-brand-foreground-highlight',
      false: 'text-foreground-secondary hover:text-foreground-highlight',
    },
  },
});
