import { isValidElement } from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Text } from '@pbx/shared/ui/common/text';

import { EPlacement, TIconProps } from './icon.types';
import { variant } from './icon.variants';

export const Icon: React.FC<TIconProps> = ({
  children,
  disabled,
  placement = EPlacement.PREFIX,
}) => {
  if (!children) return null;
  const getIcon = () => {
    if (isValidElement(children) && typeof children !== 'string') {
      return (
        <span className={cn(variant({ variant: 'icon', disabled }))}>
          {children}
        </span>
      );
    }

    return (
      <Text
        variant="ui"
        size="medium-400"
        className={cn(variant({ variant: 'string', disabled }))}
      >
        {children}
      </Text>
    );
  };

  return (
    <span className={cn(variant({ variant: 'wrapper', placement }))}>
      {getIcon()}
    </span>
  );
};
