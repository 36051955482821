import { fetchApi, TUseMutationOptions } from '@pbx/shared/api-client/api-core';
import { useMutation } from '@pbx/shared/api-client/hooks-core';

import { TMutationRequestBody, TSalesTeamExpert } from '../agents.types';

export async function getSalesExpertTeamByAddress(
  body: TMutationRequestBody
): Promise<TSalesTeamExpert[]> {
  return await fetchApi(
    `${process.env.NEXT_PUBLIC_EXPERT_TEAM_API_URL}/api/SalesExpertTeam/findbyaddress`,
    {
      method: 'POST',
      body,
      mode: 'cors',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function useGetSalesExpertTeamByAddress(
  config: TUseMutationOptions<unknown, Error, TMutationRequestBody> = {}
) {
  return useMutation<TSalesTeamExpert[], TMutationRequestBody, Error>({
    mutationFn: (variables: TMutationRequestBody) =>
      getSalesExpertTeamByAddress(variables),
    ...config,
  });
}
