'use client';

import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';

import { FormControl, FormItem, FormField as FormFieldRadix } from './../form';
import { TFormFieldProps } from './form-field.types';

export const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  children,
  className,
  parse,
  format,
}: TFormFieldProps<TFieldValues, TName>) => {
  return (
    <FormFieldRadix
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormControl
            {...field}
            onChange={(val) => {
              if (parse) {
                field.onChange(parse(val));
              } else {
                field.onChange(val);
              }
            }}
            value={format ? format(field.value) : field.value}
          >
            {children}
          </FormControl>
        </FormItem>
      )}
    />
  );
};
