import * as React from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';
import { Label } from '@pbx/shared/ui/common/form/label';
import { Error } from '@pbx/shared/ui/common/form/error';

import { Icon } from './icon/icon';
import { TInputProps } from './input.types';
import { EPlacement } from './icon/icon.types';
import { variants } from './input.variants';

export const Input = React.forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      className,
      type = 'text',
      error,
      disabled,
      label,
      icon,
      suffix,
      helper,
      id,
      labelSize = 'default-500',
      ...props
    },
    ref
  ) => {
    const getVariant = () => {
      if (disabled) return 'disabled';

      if (error) return 'error';

      return undefined;
    };

    return (
      <div className={cn('inline-flex flex-col', className)}>
        {label && (
          <Label
            helper={helper}
            htmlFor={id}
            labelSize={labelSize}
            className="mb-3"
          >
            {label}
          </Label>
        )}
        <div className="relative w-full">
          <Icon disabled={disabled}>{icon}</Icon>
          <input
            type={type}
            disabled={disabled}
            className={cn(
              variants({
                variant: getVariant(),
                iconType: typeof icon as 'string' | 'object',
                suffixType: typeof suffix as 'string' | 'object',
              }),
              className
            )}
            ref={ref}
            id={id}
            {...props}
          />
          {suffix && (
            <Icon disabled={disabled} placement={EPlacement.SUFFIX}>
              {suffix}
            </Icon>
          )}
        </div>
        {error && <Error className="mt-3">{error}</Error>}
      </div>
    );
  }
);
Input.displayName = 'Input';
