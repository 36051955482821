import React from 'react';

export const BreadcrumbContainer = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />);

BreadcrumbContainer.displayName = 'Breadcrumb Container';
