import { fetchApi, TUseQueryOptions } from '@pbx/shared/api-client/api-core';
import { useQuery } from '@pbx/shared/api-client/hooks-core';

import { TSalesExpert } from '../agents.types';
import { GET_SALES_EXPERT_TEAM_BY_DISTRICT } from '../agents.constants';

export async function getSalesExpertTeamByDistrict(district: string) {
  return await fetchApi<TSalesExpert[]>(
    `${process.env.NEXT_PUBLIC_EXPERT_TEAM_API_URL}/api/SalesExpertTeam/findbydistrict?districts=${district}`,
    {
      mode: 'cors',
    }
  );
}

export function useGetSalesExpertTeamByDistrict(
  district: string,
  config: TUseQueryOptions<TSalesExpert[]> = {}
) {
  return useQuery({
    queryKey: [GET_SALES_EXPERT_TEAM_BY_DISTRICT, district],
    queryFn: () => getSalesExpertTeamByDistrict(district),
    ...config,
  });
}
