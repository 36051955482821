import { cva } from 'class-variance-authority';

export const gridVariants = cva('grid', {
  variants: {
    smCols: {
      1: 'sm:grid-cols-1',
      2: 'sm:grid-cols-2',
      3: 'sm:grid-cols-3',
      4: 'sm:grid-cols-4',
      5: 'sm:grid-cols-5',
      6: 'sm:grid-cols-6',
      7: 'sm:grid-cols-7',
      8: 'sm:grid-cols-8',
      9: 'sm:grid-cols-9',
      10: 'sm:grid-cols-10',
      11: 'sm:grid-cols-11',
      12: 'sm:grid-cols-12',
    },
    mdCols: {
      1: 'md:grid-cols-1',
      2: 'md:grid-cols-2',
      3: 'md:grid-cols-3',
      4: 'md:grid-cols-4',
      5: 'md:grid-cols-5',
      6: 'md:grid-cols-6',
      7: 'md:grid-cols-7',
      8: 'md:grid-cols-8',
      9: 'md:grid-cols-9',
      10: 'md:grid-cols-10',
      11: 'md:grid-cols-11',
      12: 'md:grid-cols-12',
    },
    lgCols: {
      1: 'lg:grid-cols-1',
      2: 'lg:grid-cols-2',
      3: 'lg:grid-cols-3',
      4: 'lg:grid-cols-4',
      5: 'lg:grid-cols-5',
      6: 'lg:grid-cols-6',
      7: 'lg:grid-cols-7',
      8: 'lg:grid-cols-8',
      9: 'lg:grid-cols-9',
      10: 'lg:grid-cols-10',
      11: 'lg:grid-cols-11',
      12: 'lg:grid-cols-12',
    },
    smGap: {
      0: 'sm:gap-0',
      1: 'sm:gap-1',
      2: 'sm:gap-2',
      3: 'sm:gap-3',
      4: 'sm:gap-4',
      5: 'sm:gap-5',
      6: 'sm:gap-6',
      7: 'sm:gap-7',
      8: 'sm:gap-8',
      9: 'sm:gap-9',
      10: 'sm:gap-10',
      11: 'sm:gap-11',
      12: 'sm:gap-12',
    },
    mdGap: {
      0: 'md:gap-0',
      1: 'md:gap-1',
      2: 'md:gap-2',
      3: 'md:gap-3',
      4: 'md:gap-4',
      5: 'md:gap-5',
      6: 'md:gap-6',
      7: 'md:gap-7',
      8: 'md:gap-8',
      9: 'md:gap-9',
      10: 'md:gap-10',
      11: 'md:gap-11',
      12: 'md:gap-12',
    },
    lgGap: {
      0: 'lg:gap-0',
      1: 'lg:gap-1',
      2: 'lg:gap-2',
      3: 'lg:gap-3',
      4: 'lg:gap-4',
      5: 'lg:gap-5',
      6: 'lg:gap-6',
      7: 'lg:gap-7',
      8: 'lg:gap-8',
      9: 'lg:gap-9',
      10: 'lg:gap-10',
      11: 'lg:gap-11',
      12: 'lg:gap-12',
    },
  },
});
