import { PropsWithChildren, FC } from 'react';

import { cn } from '@pbx/shared/utilities/combine-class-names';

import { TGridProps } from './grid.types';
import { gridVariants } from './grid.variants';

export const Grid: FC<PropsWithChildren<TGridProps>> = ({
  children,
  columns = [1, 2, 4],
  gap = [4, 6, 8],
  className,
}) => {
  const [smCols, mdCols, lgCols] = columns;
  const [smGap, mdGap, lgGap] = gap;

  return (
    <div
      data-testid="grid"
      className={cn(
        gridVariants({ smCols, mdCols, lgCols, smGap, mdGap, lgGap }),
        className
      )}
    >
      {children}
    </div>
  );
};
