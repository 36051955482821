import { Text } from '@pbx/shared/ui/common/text';

export function Loading({ text = 'Loading' }: { text?: string }) {
  return (
    <div className="text-foreground-highlight flex h-full flex-col items-center justify-center gap-4">
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path
          d="M0,16 10,12 20,8 30,4 40,0 52,12 58,18 64,24 64,64 0,64Z"
          fill="currentColor"
        >
          <animate
            dur="5s"
            repeatCount="indefinite"
            attributeName="d"
            values="
            M0,16 10,12 20,8 30,4 40,0 52,12 58,18 64,24 64,64 0,64Z;
            M0,0 10,4 19.5,8 30,12 40,16 40,8 52.5,12 64,16 64,64 0,64Z;
            M0,0 10,4 19.5,8 30,12 40,16 40,8 52.5,12 64,16 64,64 0,64Z;
            M0,16 12,8 24,0 36,8 48,16 53,16 58,16 64,16 64,64 0,64Z;
            M0,16 12,8 24,0 36,8 48,16 53,16 58,16 64,16 64,64 0,64Z;
            M0,16 16,16 16,8 28,8 28,16 40,16 56,0 64,0 64,64 0,64Z;
            M0,16 16,16 16,8 28,8 28,16 40,16 56,0 64,0 64,64 0,64Z;
            M0,24 16,24 16,16 32,16 32,8 48,8 48,0 64,0 64,64 0,64Z;
            M0,24 16,24 16,16 32,16 32,8 48,8 48,0 64,0 64,64 0,64Z;
            M0,16 10,12 20,8 30,4 40,0 52,12 58,18 64,24 64,64 0,64Z;
            M0,16 10,12 20,8 30,4 40,0 52,12 58,18 64,24 64,64 0,64Z
            "
          />
        </path>
      </svg>
      <Text>{text}</Text>
    </div>
  );
}
