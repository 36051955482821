import { cva } from 'class-variance-authority';

import { textVariants } from '@pbx/shared/ui/common/text';

export const variants = cva(
  `${textVariants({ variant: 'ui', size: 'medium-400' })} px-4 py-3 outline-none transition-border transition-shadow focus-visible:border-input-focus bg-background-primary border rounded-medium h-12`,
  {
    variants: {
      variant: {
        default: 'border-ui',
        error: 'border-error',
        disabled:
          'border-border-disabled bg-background-disabled placeholder-foreground-disabled  text-foreground-disabled cursor-not-allowed',
      },
      iconType: {
        string: 'pl-8',
        object: 'pl-12',
      },
      suffixType: {
        string: 'pr-13',
        object: 'pr-12',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
