import {
  useMutation as useMutationTanstack,
  UseMutationOptions,
} from '@tanstack/react-query';

export const useMutation = <TData, TVariables, TError = Error>(
  props: UseMutationOptions<TData, TError, TVariables>
) => {
  return useMutationTanstack(props);
};
